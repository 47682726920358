import './src/styles/style.scss';

import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IconContext } from 'react-icons';

export const wrapRootElement = ({ element }) => {
  return (
    <IconContext.Provider value={{ size: "1rem" }}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITEKEY}>
        {element}
      </GoogleReCaptchaProvider>
    </IconContext.Provider>
  );
};
